import {LinkService} from '../../i18n/link.service'
import {IndustryItemFieldsFragment, IndustrySectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {IndustryItem, IndustrySection} from '../types/industry-section.types'
import {sanitizePathUrl, validateDatoCmsTypes} from './common.serde'
import {PUBLIC_ROUTES} from '../../../shared/routing/public.routes'
import {ApplicationRoute, LOCALIZED_ROUTE_TOKEN} from '../../../craft/utils/application.route'

export function parseIndustrySection(
  linkService: LinkService,
  section: IndustrySectionFieldsFragment,
): IndustrySection {
  return {
    __type: 'IndustrySection',
    plot: section.plot!,
    title: section.title!,
    items: parseIndustryItemCollection(linkService, section.industryItems),
  }
}

export function parseIndustryItemCollection(
  linkService: LinkService,
  items: IndustryItemFieldsFragment[],
): IndustryItem[] {
  return items.map(item => parseIndustryItem(linkService, item as IndustryItemFieldsFragment)) ?? []
}

export function parseIndustryItem(linkService: LinkService, e: IndustryItemFieldsFragment): IndustryItem {
  const entry = validateDatoCmsTypes(e)

  return {
    title: entry.title,
    icon: {
      name: entry.industryIcon,
      collection: entry.collection,
    },
    targetPath: parseIndustryItemTargetPath(linkService, entry),
  }
}

function parseIndustryItemTargetPath(linkService: LinkService, e: IndustryItemFieldsFragment): string {
  const entry = validateDatoCmsTypes(e)
  const target = validateDatoCmsTypes(entry.target)
  const {slug} = target

  switch (e.target?.__typename) {
    case 'CategoryLandingPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.CATEGORY_LANDING_PAGE, {routeParams: {pageSlug: slug}})
    case 'EmergencyLandingPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.EMERGENCY_LANDING_PAGE, {routeParams: {pageSlug: slug}})
    case 'IndustryOverviewPageModelRecord':
      return linkService.localizedPathTo(PUBLIC_ROUTES.INDUSTRY_OVERVIEW_PAGE, {routeParams: {pageSlug: slug}})
    case 'StaticPageModelRecord':
      if (!e.target?.path) {
        throw Error(`Static page ${slug} requires a path`)
      }
      return linkService.localizedPathTo(
        new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/${sanitizePathUrl(e.target?.path)}`),
      )
    default:
      throw Error('Target type not recognized for industry item')
  }
}
